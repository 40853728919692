$white: #fff;
$black: #000;

$border-color: #f3ebdc;

$slider-text: #535353;
$gray: #2d2b27;

$offers-color: #2d2b27;

$brand-color: #898989;