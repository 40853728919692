.footer {
  text-align: center;
  padding: 10px 0;

  p {
    font-size: 14px;
    line-height: 30px;
    color: #727272;
    font-weight: 300;
    margin: 20px 0;
  }

  img {
    display: block;
    margin: 40px auto 30px;
  }

}