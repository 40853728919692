.content {
  margin-top: 70px;
}

@media (max-width: 768px) {

  .content {
    margin-top: 30px;
  }

}