.banner {
  background: url('../img/banner1.jpg') left center no-repeat #00b7d9;
  background-size: cover;
  margin: 25px 0;
  padding: 45px 0;
  display: flex;
  justify-content: flex-end;

  .banner__information {
    flex-basis: 630px;
    margin-right: 70px;
    text-align: center;
  }

  .banner__title {
    font-size: 26px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: 2.6px;
    text-transform: uppercase;
  }

  p {
    color: $offers-color;
    margin: 15px 0;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 5.6px;
  }

  a {
    $values: (bg: $white, fs: 12px, lh: 20px, ls: 2.16px, w: 250px, br: 60px, clr: $black);
    @include btn($values...) {
      padding: 20px 0;
      margin: 35px auto 0px;
    };
  }

}

.banner--left {
    background: url('../img/banner2.jpg') left center no-repeat #d7dbd5;
    background-size: cover;
    justify-content: flex-start;

  .banner__information {
    margin-right: 0px;
    margin-left: 70px;
  }

  a {
    $values: (bg: $black, fs: 14px, lh: 23px, ls: 0.7px, w: 205px, br: 70px, clr: $white);
    @include btn($values...) {
      padding: 24px 0;
      margin: 35px auto 0px;
    };
  }

}

@media (max-width: 1100px) {

  .banner {
    background-position: -150px 0;

    .banner__information {
      margin-right: 0;
    }

  }

  .banner--left {

    .banner__information {
      margin-left: 0;
    }

  }

}

@media (max-width: 880px) {

  .banner {
    background: #00b7d9;

    .banner__information {
      flex-basis: 100%;
      padding: 0 10px;
      box-sizing: border-box;
    }

  }

  .banner--left {
    background: #d7dbd5;
  }

}