.menu {

  ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  a {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 23px;
    font-weight: 300;
    color: $black;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    letter-spacing: 0.96px;
    margin: 0 3px;

    &:hover {
      border-bottom-color: $black;
    }

  }


}